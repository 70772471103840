import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root' // Make sure to provide the guard at the root level or in a module where it is needed
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean | UrlTree> {
    if (this.authService.isUserTokenAvailable) {
      console.log('token found');
      return true;
    } else {
      localStorage.setItem('showDetailPage', window?.location?.href);
      console.log(' no token found');
      this.authService.logOut();
    }
  }
}
