import { Component, OnDestroy, OnInit } from '@angular/core';
import { StorageService } from './core/common/storage.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'sondr-frontend-host-angular';
  isRedirectScreen: boolean = true;
  constructor(private route: ActivatedRoute){

  }
 ngOnInit(){
  this.hideMobileRedirectScreen();
 }

 hideMobileRedirectScreen(){ 
  this.route.params.subscribe( params => {
    const path = params['redirect'];

    if(path === 'redirect'){
      this.isRedirectScreen = false;
    }
  })
  }
  ngOnDestroy(){
    this.isRedirectScreen = true;

  }
}
