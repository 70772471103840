import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/common/api.service';
import { StorageService } from '@app/core/common/storage.service';

@Component({
  selector: 'app-profile-image-popup',
  templateUrl: './profile-image-popup.component.html',
  styleUrls: ['./profile-image-popup.component.scss']
})
export class ProfileImagePopupComponent implements OnInit {
  selectedProfileImage: any;
  defaultImgUrl = 'assets/Images/user.png'
  imageUrl: any = 'assets/Images/user.png';
  userId: any;
  getProfileImg: any;
  selectedFile: File;

  @Output() buttonValue: any = new EventEmitter<boolean>();

  constructor(
    private apiService: ApiService,
    private storage: StorageService
  ) {}

  ngOnInit() {
    this.userId = this.storage.userID;
    console.log(this.userId);
    this.getProfileImage();

  }

  getProfileImage() {

    const url = `getUserProfileDetails?ID=${this.userId}`;

    this.apiService.httpGet(url).subscribe((res) => {
      this.getProfileImg = res?.data?.profilePhoto;
      if (this.getProfileImg) {
        this.imageUrl = this.getProfileImg;
      }
    });
  }

  onFileSelected(e: any) {
    this.selectedFile = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
      console.log(e.target.result);
    };
    reader.readAsDataURL(this.selectedFile);

  }

  upload() {
    const url = 'uploadProfilePic';
    const formData = new FormData();
    formData.append('photo1', this.selectedFile);
    formData.append('ID', this.userId);
    console.log('photo1', this.selectedFile.name);

    this.apiService.httpPost(url, formData).subscribe((res) => {
      console.log(res);
      if (res.status === true) {
        this.cancel();
      }
    }, (error) => {
      console.log(error);
    });
  }
 
  cancel(source?:any){
    if(!source) {
      event.stopPropagation();
    }
  this.buttonValue.emit(null);
  }
}
