<nav class="bg-gray-800 p-4 w-full block md:hidden relative" *ngIf="hideNave">
  <div class="flex items-center justify-between">
    <!-- Left side: Logo -->
    <div class="flex items-center">
      <div class="logo">
        <img src="assets/Images/login/logo.png" class="h-6 object-cover" alt="Logo" (click)="navTo('/')">
      </div>
    </div>

    <!-- Right side: Hamburger Menu -->
    <div class="flex items-center gap-6">
      <div>
        <img
          [src]="showScreen==='notification' ? 'assets/Icons/events-list/Bell-active.png' : 'assets/Icons/events-list/Bell.png'"
          class="w-6" alt="Bell">
      </div>
      <button class="text-white block lg:hidden" (click)="toggleMenu()">
        <svg class="h-6 w-6" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M28 16C28 16.2652 27.8946 16.5196 27.7071 16.7071C27.5196 16.8946 27.2652 17 27 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16C4 15.7348 4.10536 15.4804 4.29289 15.2929C4.48043 15.1054 4.73478 15 5 15H27C27.2652 15 27.5196 15.1054 27.7071 15.2929C27.8946 15.4804 28 15.7348 28 16ZM5 9H27C27.2652 9 27.5196 8.89464 27.7071 8.70711C27.8946 8.51957 28 8.26522 28 8C28 7.73478 27.8946 7.48043 27.7071 7.29289C27.5196 7.10536 27.2652 7 27 7H5C4.73478 7 4.48043 7.10536 4.29289 7.29289C4.10536 7.48043 4 7.73478 4 8C4 8.26522 4.10536 8.51957 4.29289 8.70711C4.48043 8.89464 4.73478 9 5 9ZM27 23H5C4.73478 23 4.48043 23.1054 4.29289 23.2929C4.10536 23.4804 4 23.7348 4 24C4 24.2652 4.10536 24.5196 4.29289 24.7071C4.48043 24.8946 4.73478 25 5 25H27C27.2652 25 27.5196 24.8946 27.7071 24.7071C27.8946 24.5196 28 24.2652 28 24C28 23.7348 27.8946 23.4804 27.7071 23.2929C27.5196 23.1054 27.2652 23 27 23Z"
            fill="white" />
        </svg>
      </button>
    </div>
  </div>

  <div [ngClass]="{ 'hidden': !showMenu, 'block': showMenu }"
    class="mobile_menu_screen lg:hidden text-primary-white text-center backdrop-blur-xl bg-white/30 absolute top-0 left-0 w-screen h-screen transition-opacity duration-300">
    <div class="close-btn flex justify-end m-4 text-primary-white">
      <img src="assets/Icons/nav/x.png" class="w-6" alt="close button" (click)="toggleMenu()">
    </div>
    <div class="flex justify-center gap-4 text-white py-6 cursor-pointer" (click)="navTo('events-list')">
      <img
        [src]="isActive('/events') || isActive('/events-list/event-details')|| isActive('/events-list/events') ? 'assets/Icons/events-list/Calendar-active.png' : 'assets/Icons/events-list/Calendar.png'"
        class="w-6" alt="Calendar">Events
    </div>
    <div class="flex justify-center gap-4 text-white py-6 cursor-pointer" (click)="navTo('analytics')">
      <img
        [src]="isActive('/analytics/analytics-overview')||isActive('/analytics/analytics-details') ||isActive('/analytics/event-details') ? 'assets/Icons/events-list/Graph-active.png' : 'assets/Icons/events-list/Graph.png'"
        class="w-6" alt="Graph">Analytics
    </div>
    <div class="flex justify-center gap-4 text-white py-6 cursor-pointer" (click)="navTo('payout')">
      <img
        [src]="isActive('/payout/payout-list') ? 'assets/Icons/events-list/Dollar-active.png' : 'assets/Icons/events-list/Dollar.png'"
        class="w-6" alt="Dollar">Payout
    </div>
    <div class="flex justify-center gap-4 text-white py-6 cursor-pointer" (click)="showProfile()">
      <img
        [src]="'assets/Images/user.png'"
        class="w-6" alt="profile-image">Profile
    </div>
    <div class="flex justify-center gap-4 text-white py-6 cursor-pointer" (click)="logOut()"> Log out</div>
  </div>
</nav>

<app-profile-image-popup *ngIf="showProfilePopup" (buttonValue)="showProfile()"></app-profile-image-popup>