import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  layoutChange = new Subject();
  getArrowBtnValue = new Subject();
  
  private loginOtp: string;


  constructor() {}

  getEventPublishLayout(value: any) {
     this.layoutChange.next(value);
  }
  removeLocalStorageData(key: string) {
    localStorage.removeItem(key);
  }
   get usertoken(): any{
    return localStorage.getItem('user_token')? localStorage.getItem('user_token'): '';
  };
   get userID(): any{
    return localStorage.getItem('userID')? localStorage.getItem('userID'): '';
  };
  get userMobileNumber(): any {
    return localStorage.getItem('userMobileNumber')? localStorage.getItem('userMobileNumber'): '';
  }
  eventsArrowBtn(value: string){
    this.getArrowBtnValue.next(value);
  }
  validatePhoneNumber(phoneNumber) {
  const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, '');

  const phoneNumberPattern = /^\d+$/;
  return phoneNumberPattern.test(sanitizedPhoneNumber);
  }

  validateEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
}

 
  setLoginOtp(otp: string): void {
    this.loginOtp = otp;
  }

  getLoginOtp(): string {
    return this.loginOtp;
  }
  logout(){
    localStorage.removeItem('eventId');
    localStorage.removeItem('editEventId');
    localStorage.removeItem('uuid');
    localStorage.removeItem('hostUserId');
    localStorage.removeItem('user_token');
    localStorage.removeItem('userID');
  }

  isIOS(): boolean {
    const isIos = /iPad|iPhone|iPod/.test(navigator.platform)
      || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    
    return isIos;
  }

  isDesktop(): boolean {
    return !this.isIOS();
  }
}
