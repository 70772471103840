import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TestComponent } from './shared/components/test/test.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { AuthGuard } from './core/validators/auth.guard';
import { AuthIsSignInGuard } from './core/validators/authIsSignIn.guard';
import { RefundRedirectComponent } from './shared/refund-redirect/refund-redirect.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/login-signup/login-signup.module').then(m => m.LoginSignupModule),
    canActivate: [AuthIsSignInGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./modules/events-create-edit/events-create-edit.module').then(m => m.EventsCreateEditModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'events-list',
    loadChildren: () => import('./modules/events-list/events-list.module').then(m => m.EventsListModule),
  },
  {
    path: 'analytics',
    loadChildren: () => import('./modules/analytics/analytics.module').then(m => m.AnalyticsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payout',
    loadChildren: () => import('./modules/payout/payout.module').then(m => m.PayoutModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'depositRefund',
    component: RefundRedirectComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'redirect',
    component: TestComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
