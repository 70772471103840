import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "@env/environment";
import { StorageService } from "./storage.service";
import { Router } from '@angular/router';
import { Observable, catchError, finalize, map, throwError } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  token: any;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private storage: StorageService,
    private router: Router,

  ) {}
  handleError(error: any): any {
    this.toastr.clear()
    if (error.status === 404) {
      if (error.error === "Not Found") {
        return false
      }
    } else if (error.status === 412) {
      this.toastr.error('', error.error.error);
    } else if (error.status === 400) {
      this.toastr.error('', error.error.error);
    } else if (error.status === 500) {
      this.toastr.error('', error.error.error);
      console.log("Error --", error);
      if (error.error.error?.includes('408') || error.error?.includes('408')) {
        localStorage.removeItem('user_token');
        localStorage.removeItem('userID');
        localStorage.removeItem('eventId');
        localStorage.removeItem('hostUserId');
        localStorage.removeItem('uuid');
        localStorage.clear();
        this.router.navigate(['/auth']);
      }
    }
    else if (error.status === 401) {
      this.toastr.error('', error.error.error);

    } else if (error.status === 403) {
      this.toastr.error('', error.error.error);
    } 
    else {
      this.storage.removeLocalStorageData('token')
      this.toastr.error('', 'Internet connection lost');
    }

    return error.error;
  }


  checkResponse(response: any): any {
    const results = response;
    if (results.success || results.status) {
      return results;
    } else {
      return { error: results.error };
    }
  }

  getToken() {
    this.token = this.storage.usertoken ? this.storage.usertoken : '';
    return this.token;
  }

  getUserToken() {
    return localStorage.getItem('user_token') ? localStorage.getItem('user_token') : '';
  }

  httpPost(url: string, data: any): Observable<any> {
    this.spinner.show();
    const Link = environment.baseUrl + url;
    const header = new HttpHeaders().set('Authorization', 'Bearer' + ' ' + this.getUserToken());
    const headers = { headers: header };
    return this.http.post(Link, data, headers)
      .pipe(map(Response => this.checkResponse(Response)),
        catchError((error) => throwError(this.handleError(error))), finalize(() => this.spinner.hide()));
  }

  httpGet(url: string, isUser?: boolean, dontShowLoader?: boolean): Observable<any> {
    if (!dontShowLoader) {
      this.spinner.show();
    }
    const Link = environment.baseUrl + url;
    const header = new HttpHeaders().set('Authorization', 'Bearer' + ' ' + this.getUserToken());

    const headers = { headers: header };
    // debugger
    return this.http.get(Link, headers)
      .pipe(map(Response => this.checkResponse(Response)),
        catchError((error) => throwError(this.handleError(error))), finalize(() => {
          if (!dontShowLoader) {
            this.spinner.hide()
          }
        }
        ));
  }
  getLinkPreviewHtml(eventId: number): Observable<string> {
    const apiUrl = `get-event/${eventId}/preview`;
    const Link = environment.baseUrl + apiUrl
    return this.http.get(Link, { responseType: 'text' });
  }
  httpPatch(url: string, data: any): Observable<any> {
    this.spinner.show();
    const Link = environment.baseUrl + url;
    const header = new HttpHeaders().set('Authorization', 'Bearer' + ' ' + this.getUserToken());
    const headers = { headers: header };
    return this.http.patch(Link, data, headers)
      .pipe(map(Response => this.checkResponse(Response)),
        catchError((error) => throwError(this.handleError(error))), finalize(() => this.spinner.hide()));
  }

  httpPut(url: string, data: any): Observable<any> {
    this.spinner.show();
    const Link = environment.baseUrl + url;
    const header = new HttpHeaders().set('Authorization', 'Bearer' + ' ' + this.getUserToken());
    const headers = { headers: header };
    return this.http.put(Link, data, headers).pipe(
      map((Response) => this.checkResponse(Response)),
      catchError((error) => throwError(this.handleError(error))), finalize(() => this.spinner.hide()));
  }

  httpDel(url?: string, id?: number): Observable<any> {
    this.spinner.show();
    const Link = environment.baseUrl + url;
    const header = new HttpHeaders().set('Authorization', 'Bearer' + ' ' + this.getUserToken());
    const headers = { headers: header };
    return this.http.delete(Link)
      .pipe(map(Response => this.checkResponse(Response)),
        catchError((error) => throwError(this.handleError(error))), finalize(() => this.spinner.hide()));
  }
  httpGetPromise(url, isUser) {
    const Link = environment.baseUrl + url;
    const header = new HttpHeaders().set('Authorization', 'Bearer' + ' ' + this.getUserToken());

    const headers = { headers: header };
    return this.http
      .get<any>(Link, headers)
      .toPromise()
      .then((response) => {
        console.log('Response', response)
      });


  }

}