export const environment = {
    production: true,
    siteUrl: 'https://www.joinsocha.com/',
    // baseUrl: 'https://web-socha-dev.devtomaster.com/api/v1/',
    stripeKey: "pk_live_51NE9vsAJdaiSPcX3HBlAQfXZAmOTMPro4ATteTpK5IExljBtVzadmrVD9dtuv0wvA4TTRtJzxtDcjdFuHKBXBSWV00Xqk6EOj8",
    // stripeKey: "pk_test_51NAmKfC61O1WseSggxxCEKvFV9EnTfaPG42hnsMov7rxy8s1Pqc9i12EGo2Af0Dww0FKzg3aEIODv1CdZm2AqelI00SXmGxOPj",
    baseUrl: 'https://api.joinsocha.com/api/v1/',
    shareLinkUrl: 'https://www.joinsocha.com/',
    profileBaseUrl: 'https://sondr-be-prod.s3.us-east-2.amazonaws.com/',

    firebase: {
        apiKey: "AIzaSyBNKSJo8jNwSs2E207TwnWCbt_svyIRI50",
        authDomain: "socha-firebase.firebaseapp.com",
        databaseURL: "https://socha-firebase-default-rtdb.firebaseio.com",
        projectId: "socha-firebase",
        storageBucket: "socha-firebase.appspot.com",
        messagingSenderId: "543609127488",
        appId: "1:543609127488:web:36790fad78cb7ded9655a5",
        measurementId: "G-MS7SZEJNF0"
    },

    cometChatApiKey: 'bc9ca090b7ebe849cd7467cd74dd1f90d3c5c7f5',
    cometChatAppID: '254003b9bfae2074',
    cometChatAuthKey: 'bc9ca090b7ebe849cd7467cd74dd1f90d3c5c7f5',

    googleURL: "login-gl?UUID=",
    facebookURL: "login-fb?UUID=",
    appleURL: "login-apple?UUID=",
}