import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './components/input/input.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { ButtonComponent } from './components/button/button.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { EventHeaderComponent } from './components/event-header/event-header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LandingpageHeaderComponent } from './components/landingpage-header/landingpage-header.component';
import { TicketCardComponent } from './components/ticket-card/ticket-card.component';
import { ToggleTabComponent } from './components/toggle-tab/toggle-tab.component';
import { PromocodeCardComponent } from './components/promocode-card/promocode-card.component';
import { PayoutCardComponent } from './components/payout-card/payout-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventCardComponent } from './components/event-card/event-card.component';
import { ArrowButtonComponent } from './components/arrow-button/arrow-button.component';
import { TestComponent } from './components/test/test.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SideNavBarComponent } from './components/side-nav-bar/side-nav-bar.component';
import { DropdownCheckboxComponent } from './components/dropdown-checkbox/dropdown-checkbox.component';
import { AnalyticsChartComponent } from './components/analytics-chart/analytics-chart.component';
import { ChartModule } from 'angular-highcharts';
import { AnalyticsEventCardComponent } from './components/analytics-event-card/analytics-event-card.component';
import { AnalyticsDetailChartComponent } from './components/analytics-detail-chart/analytics-detail-chart.component';
import { RightSideBarComponent } from './components/right-side-bar/right-side-bar.component';
import { CustomCheckBoxComponent } from './components/custom-check-box/custom-check-box.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '@env/environment';
import { GoogleMapsModule } from '@angular/google-maps';
import { MobileNavComponent } from './components/mobile-nav/mobile-nav.component';
import { PopupComponent } from './components/popup/popup.component';
import { IosPopupComponent } from './components/ios-popup/ios-popup.component';
import { LastFourDigitsPipe } from './pipes/last-four-digits.pipe';
import { ProfileImagePopupComponent } from './components/profile-image-popup/profile-image-popup.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LineBreaksToBrPipe } from './pipes/line-breaks-to-br.pipe';
import { PayoutComponent } from './components/payout/payout.component';
import { DepositRefundComponent } from './deposit-refund/deposit-refund.component';
import { RefundRedirectComponent } from './refund-redirect/refund-redirect.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    InputComponent,
    ToggleButtonComponent,
    ButtonComponent,
    TextareaComponent,
    EventHeaderComponent,
    FooterComponent,
    LandingpageHeaderComponent,
    TicketCardComponent,
    ToggleTabComponent,
    PromocodeCardComponent,
    PayoutCardComponent,
    EventCardComponent,
    ArrowButtonComponent,
    TestComponent,
    PageNotFoundComponent,
    SideNavBarComponent,
    DropdownCheckboxComponent,
    AnalyticsChartComponent,
    AnalyticsEventCardComponent,
    AnalyticsDetailChartComponent,
    RightSideBarComponent,
    CustomCheckBoxComponent,
    NotificationsComponent,
    MobileNavComponent,
    LastFourDigitsPipe,
    LineBreaksToBrPipe,
    PopupComponent,
    IosPopupComponent,
    ProfileImagePopupComponent,
    PayoutComponent,
    DepositRefundComponent,
    RefundRedirectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ChartModule,
    GoogleMapsModule,
    ImageCropperModule,
    NgxStripeModule.forRoot(environment.stripeKey)
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    InputComponent,
    ToggleButtonComponent,
    ButtonComponent,
    TextareaComponent,
    EventHeaderComponent,
    FooterComponent,
    LandingpageHeaderComponent,
    TicketCardComponent,
    ToggleTabComponent,
    PromocodeCardComponent,
    PayoutCardComponent,
    EventCardComponent,
    ArrowButtonComponent,
    SideNavBarComponent,
    DropdownCheckboxComponent,
    ChartModule,
    AnalyticsChartComponent,
    AnalyticsDetailChartComponent,
    AnalyticsEventCardComponent,
    RightSideBarComponent,
    CustomCheckBoxComponent,
    MobileNavComponent,
    PopupComponent,
    PayoutComponent,
    LastFourDigitsPipe,
    LineBreaksToBrPipe,
    GoogleMapsModule,
    NgxStripeModule,
    IosPopupComponent,
    ProfileImagePopupComponent,
    ImageCropperModule,
    DepositRefundComponent
  ],

})
export class SharedModule {}
