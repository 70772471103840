export const environment = {
  production: true,
  siteUrl: 'https://www.joinsocha.dev/',
  // baseUrl: 'https://web-socha-dev.devtomaster.com/api/v1/',
  stripeKey: "pk_test_51NE9vsAJdaiSPcX39dgVFKS5NX3jPIjfFLuy7nXjjlYTGXJboRNBZmQ1hJGgy1cX1dvseeNUF2bV3iwTqtzLjlEI00MVRdBlml",
  // baseUrl: 'https://api-sondr-stag.devtomaster.com/api/v1/',
  baseUrl: 'https://api.joinsocha.dev/api/v1/',
  // shareLinkUrl: 'https://api-sondr-stag.devtomaster.com/',
  shareLinkUrl: 'https://links-sondr-stag.accubits.net/',
  profileBaseUrl: 'https://sondr-dev.s3.us-east-2.amazonaws.com/',
  firebase: {
    apiKey: "AIzaSyAidC5cfg3Sg8pFXuxO1cuFTP0Q-CQ1R6c",
    authDomain: "join-socha.firebaseapp.com",
    projectId: "join-socha",
    storageBucket: "join-socha.appspot.com",
    messagingSenderId: "424490782397",
    appId: "1:424490782397:web:53820edd677213fa39f1d4",
    measurementId: "G-XZW9G3ZJPJ"
  },

  cometChatApiKey: 'e404387686a5ca44e5757e30a93c557db6750145',
  cometChatAppID: '237126534d2e5eb8',

  googleURL: "login-gl?UUID=",
  facebookURL: "login-fb?UUID=",
  appleURL: "login-apple?UUID=",
}