import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit, OnDestroy {

  constructor(private router: Router){}
  ngOnInit() {
    localStorage.setItem('redirect', 'true');
  }

  close(){
    window.close();
  }
  ngOnDestroy(): void {
    localStorage.removeItem('redirect');
  }
}
