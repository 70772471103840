import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '@app/core/common/spinner.service';
import { StorageService } from '@app/core/common/storage.service';

@Component({
  selector: 'app-refund-redirect',
  templateUrl: './refund-redirect.component.html',
  styleUrls: ['./refund-redirect.component.scss']
})
export class RefundRedirectComponent implements OnInit {

  constructor(
    private storage: StorageService,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.route.queryParams.subscribe((queryParams) => {
      let params : any = queryParams;
      localStorage.setItem('refundRedirect' , JSON.stringify(params));
      // console.log('All query parameters:', queryParams);
      const isLoggedIn : boolean = this.storage.userID ? true : false;
   
      if (isLoggedIn) {
      this.router.navigate(['/payout/payout-list'], {queryParams: params})
      } else {
        this.router.navigate(['/auth'])
      }
    });
  }

}
