<!-- <app-ios-popup></app-ios-popup> -->
<app-mobile-nav *ngIf="isRedirectScreen"></app-mobile-nav>
<router-outlet>
    <ngx-spinner class="z-[1000]">
        <div class="la-ball-scale-multiple la-2x">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </ngx-spinner>
</router-outlet>
