import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { StorageService } from "./common/storage.service";
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class AuthService {
    isLoggedIn: boolean = false;
    constructor(
        private router: Router,
        private storage: StorageService,
        private http: HttpClient) {}


    get isUserTokenAvailable(): boolean {
        let userToken = this.storage.usertoken ? true : false;
        return userToken;
    }

    logOut() {
        localStorage.removeItem('user_token');
        localStorage.removeItem('userID');
        localStorage.removeItem('eventId');
        localStorage.removeItem('hostUserId');
        localStorage.removeItem('uuid');
        this.router.navigate(['/auth']);
    }
    isAuthenticated() {
        return this.isLoggedIn;
    }

    getOtp(data: any) {
        return this.http.post(`${environment.baseUrl}createOtp`, data);
    }
}