import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@app/core/common/storage.service';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent implements OnInit {
  showMenu: boolean = false;
  showScreen = '';
  hideNave: boolean = true;
  showProfilePopup = false;
  constructor(private router: Router, private storage: StorageService) {}

  ngOnInit() {
    const isRedirectPage = localStorage.getItem('redirect');
    if (isRedirectPage == 'true' || !this.storage.userID) {
      this.hideNave = false;
    } else {
      this.hideNave = true;
    }
  }
  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
  navTo(routeLink) {
    this.router.navigate([routeLink]);
    this.showMenu = false
    // this.toggleMenu();
  }

  showProfile() {
    this.showMenu = false;
    this.showProfilePopup = !this.showProfilePopup;
  }

  isActive(route: string): boolean {
    return this.router.url === route;
  }
  logOut() {
    localStorage.removeItem('eventId');
    localStorage.removeItem('editEventId');
    localStorage.removeItem('uuid');
    localStorage.removeItem('hostUserId');
    localStorage.removeItem('user_token');
    localStorage.removeItem('userID');
    this.showMenu = false;
    if(window.location.href.includes('home')) {      
      window.location.reload();
    }
    else {
      this.router.navigate(['/']);
    }
  }
}
