<div class="popupOuter backdrop-blur-xl bg-white/30 z-[200]">
  <div class="popupOverlay" (click)="cancel('source')"></div>
  <div class="popup-content">
    <h4 class="text-lg text-primary-white text-center mb-4">Upload profile picture</h4>
    <div class="sideNav animateNav bg-primary-black border-dashed border-2 border-[grey]">
      <div
        class="content-wrapper w-[19rem] h-[19rem] bg-cover bg-center bg-no-repeat flex justify-center items-center relative"
        [style.backgroundImage]="'url(' + imageUrl + ')'">
        <input type="file" id="fileInput" accept=".jpg,.png" (change)="onFileSelected($event)"
          [(ngModel)]="selectedProfileImage" hidden>
        <label for="fileInput" class="text-primary-white inline-block rounded-[50px] bg-[#2E3034] cursor-pointer">
          <img src="assets/Icons/profile/add.png" alt="">
        </label>
        <!-- <div *ngIf="imageUrl !== defaultImgUrl" class="absolute top-2 right-2 ">
            <img (click)="deleteImage()" class="w-6 cursor-pointer" src="assets/Images/events/delete_icon.png" alt="">
        </div> -->
      </div>
    </div>
    <div class="buttons flex justify-center items-center mt-4">
      <button
        class="text-primary-white bg-tertiary-gray font-medium text-xs md:text-sm xs:p-3 sm:p-4 py-[.3rem] px-[.5rem] md:py-[.45rem] md:px-[1rem] mr-[1rem] rounded-[10px]"
        (click)="cancel()">
        Cancel
      </button>
      <button *ngIf="selectedFile"
        class="text-primary-white bg-primary-orange font-medium text-xs md:text-sm xs:p-3 sm:p-4 py-[.3rem] px-[.5rem] md:py-[.45rem] md:px-[1rem] rounded-[10px]"
        (click)="upload()">
        Upload +
      </button>
    </div>
  </div>
</div>